<template>
  <div class="articlePub">
    <van-nav-bar
      title
      left-text
      left-arrow
      :fixed="false"
      :border="false"
      :safe-area-inset-top="true"
      @click-left="onClickLeft"
    />
    <div class="artItem">
      <h1>标题</h1>
      <van-field
        v-model="name"
        name="name"
        label
        placeholder="请输入标题"
        :rules="[{ required: true, message: '请填写标题' }]"
      />
    </div>
    <!-- <div class="artItem pic"> -->
    <div class="artItem">
      <h1>封面图</h1>
      <div style="display: flex;">
        <div style="position: relative;padding:0.1rem 0;" v-if="picList.length>0">
          <div
            role="button"
            style="top:0.1rem;"
            @click="imgDelete"
            class="van-uploader__preview-delete"
          >
            <i class="van-badge__wrapper van-icon van-icon-cross van-uploader__preview-delete-icon"></i>
          </div>
          <img
            style="height:80px;max-width:80px;object-fit: cover;display:block;"
            :src="adddom(picList[0])"
          />
        </div>
        <van-uploader
          accept="image/*"
          v-if="picList.length == 0"
          multiple
          v-model="fileList"
          :max-count="1"
          :before-read="beforeRead"
          :after-read="afterRead"
          :before-delete="beforeDelete"
        >
          <van-button class="upImg" icon="plus" type="primary"></van-button>
        </van-uploader>
      </div>
    </div>
    <div class="artItem">
      <h1>内容</h1>
      <van-field
        v-model="message"
        rows="2"
        autosize
        label
        type="textarea"
        maxlength="2000"
        placeholder="请输入内容"
        show-word-limit
      />
    </div>
    <div class="artItem reason" v-show="remark">
      <h1>驳回原因：</h1>
      <div class="desc">{{remark}}</div>
    </div>
    <div class="submitHold">
      <van-button round plain :loading="submitLoading" class="submit" type="info" @click="submit">提交</van-button>
    </div>
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
import indexFooter from "./footer.vue";
import Cookies from "js-cookie";
export default {
  name: "articlePub",
  setup() {
    const onClickLeft = function() {
      history.back();
    };
    return {
      onClickLeft
    };
  },
  data() {
    return {
      name: "",
      message: "",
      fileList: [],
      picList: [],
      remark: "",
      loadShow: false,
      submitLoading: false // 按钮加载状态
    };
  },
  components: {
    indexFooter
  },
  created() {
    // window.document.title = '“新文化符号”出海'
    if (this.$route.query.id) {
      this.loadShow = true;
      this.getDetail();
    }
  },
  methods: {
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    getDetail() {
      var that = this;
      var params = {
        token: Cookies.get("spToken"),
        id: that.$route.query.id
      };
      that.$api.article.myDetail(params).then(function(res) {
        that.loadShow = false;
        if (res.code == 200) {
          that.name = res.result.title;
          that.message = res.result.content;
          that.remark = res.result.remark;
          that.picList[0] = res.result.thumb;
        }
      });
    },
    beforeRead(file) {
      // console.log(file);
      // if (file.type !== 'image/jpeg') {
      //   Toast('请上传 jpg 格式图片');
      //   return false;
      // }
      return true;
    },
    afterRead(file) {
      var that = this;
      // console.log("file", file.content);
      var params = {
        imgFile: file.content
      };
      that.$api.index.upload(params).then(function(res) {
        if (res.code == 200) {
          // console.log(res.result.url);
          that.picList[0] = res.result.url;
          // console.log("picList", that.picList);
        }
      });
    },
    imgDelete() {
      console.log(999);
      this.picList = [];
      this.fileList = [];
    },
    beforeDelete(e, detail) {
      // console.log(e);
      // console.log(detail);
      var index = detail.index;
      this.fileList.splice(index, 1);
      this.picList.splice(index, 1);
      // console.log("删",this.picList);//缩略图数组
    },
    // 点击提交
    submit() {
      console.log(this.fileList);
      // console.log("标题",this.name);
      // console.log("封面图",this.picList[0]);
      // console.log("内容",this.message);
      var that = this;
      that.submitLoading = true;
      var params = {
        token: Cookies.get("spToken"),
        title: that.name,
        thumb: that.picList[0],
        content: that.message,
        id: this.$route.query.id
      };
      if (this.$route.query.id) {
        that.$api.article.edit(params).then(function(res) {
          that.submitLoading = false;
          if (res.code == 200) {
            that.$toast("提交成功");
            that.$router.go(-1);
          }
        });
      } else {
        that.$api.article.create(params).then(function(res) {
          that.submitLoading = false;
          if (res.code == 200) {
            that.$toast("提交成功");
            that.$router.go(-1);
          }
        });
      }
    }
  }
};
</script>
<style>
.articlePub .artItem.reason h1:before {
  display: none;
}
.articlePub .artItem.reason .desc {
  font-size: 0.37rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: red;
  padding: 10px 0;
}
</style>
